<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalEnabledStatistics" :title="$t('enabledStatistics')">
      <b-form-group :label="$t('selectOption')">
        <b-form-radio v-model="teamId" name="team" :value="fixture.team_local">{{ fixture.team_local_name }}</b-form-radio>
        <b-form-radio v-model="teamId" name="team" :value="fixture.team_visit">{{ fixture.team_visit_name }}</b-form-radio>
        <b-form-radio v-model="teamId" name="team" value="ALL">{{ $t('all') }}</b-form-radio>
      </b-form-group>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalEnabledStatistics')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fixture: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      fixtureId: this.$route.params.fixtureId,
      teamId: null
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    save () {
      const payload = {
        team_local: this.fixture.team_local,
        team_visit: this.fixture.team_visit,
        team_id: this.teamId
      }
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/enabled-statistics`
      this.$axios.put(path, payload).then((response) => {
        this.$toastr.success(response.data.message)
        this.$root.$emit('bv::hide::modal', 'modalEnabledStatistics')
      })
    }
  }
}
</script>
